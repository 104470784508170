ul.MuiList-root.MuiMenu-list.MuiList-padding{
    border-left: 0px;
}
.search-icon-with-plus{
    margin-left:-45px !important; 
    margin-top:-11px !important; 
    margin-right: 10px !important;
}
.my-scrollbar {
	position: relative;
    height: 300px;
    overflow: auto;
    overflow-x:hidden !important;
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	background-color: #F5F5F5;
    width: 6px;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #736f6f;
}

.inputWithButton {
  position: relative;
  width: 100%;
}

.inputWithButton input{
    width: 100%;
    height: 48px;
    padding-right: 60px;
}

.inputWithButton button {
    position: absolute;
    right: 4px;
    bottom: 6px;
}

.withSearchFilterDropdown button {
    position: absolute;
    right: 3%;
    bottom: 0%;
    height: 100%;
    border-radius: 0 4px 4px 0 !important;
}

.noSearchFilterDropdown button {
    position: absolute;
    right: 0%;
    bottom: 0%;
    height: 48px;
    border-radius: 0 4px 4px 0 !important;
}

/* .MuiOutlinedInput-input {
    padding: 14px 30px !important;
} */

.MuiInputBase-root {
    height: 40px; 
}
#markant-ekg-code-list {
    margin-top: -11px;
}
.search-pagination ul {
    border-left: 0px !important;
}

