
ul.tree, ul.tree ul {
    list-style: none;
     margin: 0;
     padding: 0;
     
   }
ul.tree ul {
    margin-left: 50px;
}
ul.tree li {
    margin: 0;
    padding: 0 7px;
    line-height: 20px;
    border-left: 0px solid rgb(100,100,100);

}
ul.tree li:last-child {
    border-left:none;
}

ul:not(.tree) {
    border-left: 1px solid rgb(100,100,100);
}


ul.tree li:before {
    position: relative;
    top: -0.2em;
    height: 2em;
    width: 24px;
    color:white;
    border-bottom: 1px solid rgb(100,100,100);
    content: "";
    display: inline-block;
    left: -7px;
}

ul.tree li:last-child:before {
    border-left: 0px solid rgb(100,100,100);
}

.box_trade_item:before {
    width: 100px;
    height: 100px;
    // background-image: url("/assets/images/box_trade_item.png");
}

ul.remove_border li:before {
    border-bottom: 0px solid rgb(100,100,100);
}

ul.remove_border li:last-child:before {
    border-left: 0px solid rgb(100,100,100);
}

ul.return_border li:before {
    border-bottom: 1px solid rgb(100,100,100);
}

ul.return_border li:last-child:before {
    border-left: 1px solid rgb(100,100,100);
}

.trade_item_title {
    font-size: 20px;
}

.trade_item_supplier_number {
    font-size: 15px;
}

.trade_item_supplier_number {
    font-size: 15px;
}

.trade_item_title {
    font-size: 20px;
}

.place_top_right {
    position: absolute;
    right: 10px;
    top: 10px;
}

.accordian_table {
    > tbody > tr > td {
        color: rgba(34, 42, 66, 0.7) !important;
 
    }
 
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td{
        border-color: rgba(34, 42, 66, 0.2);
        padding: 12px 7px;
        vertical-align: middle;
    }
 
    > thead > tr > th, button.btn-neutral.btn-link{
      color: rgba(34, 42, 66, 0.7);
    }
}
